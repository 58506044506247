import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`The Green Committee is committed to supporting PSI becoming a `}<a parentName="p" {...{
          "href": "https://www.ecoschools.global/"
        }}>{`UNESCO Eco School`}</a>{`.`}</p>
    </blockquote>
    <p>{`“Eco-Schools is the largest global sustainable schools programme – it starts in the classroom and expands to the community by engaging the next generation in action-based learning.” Eco Schools have 12 different themes that students can study to guide their actions. PSI’s Green Committee has chosen to focus on three areas:`}</p>
    <p>{`Biodiversity: Examines the flora and fauna present in the school environment and suggests ways to increase the levels of biodiversity around the school and raises the pupils’ awareness of biodiversity and nature.`}</p>
    <p>{`Energy: Suggests ways in which all members of the school can work together to increase awareness of energy issues and to improve energy efficiency within the school.`}</p>
    <p>{`Waste: Examines the impact of waste on the environment and explores actions to minimise the amount of waste that we produce and dispose of on a daily basis.`}</p>
    <p>{`This year our Student Green teams worked together on joint projects. This was a wonderful way for students from Grade 3 to Grade 9 to work together on common goals. All of our meetings were online once a week during the ECA time. Students chose to work on different projects and during our time students were working in small groups in break out rooms to complete their various projects.`}</p>
    <p>{`At the end of the year the students have a lot to show for their efforts. One group created a video game that highlights the effects of nuclear waste on our oceans. Another group created an alphabet book geared towards younger learners that highlights important concepts in nature. One group began researching the oceans and the “Great Garbage Patch” and are exploring ways they can work on this problem. To tie it all together, several students worked on a Green Team Website. This website showcases all of these projects as well as projects that different students are doing throughout the school. The aim for the website is to encourage more students and parents to find out  what is going on within the school to highlight sustainability and to encourage more people to live a sustainable life.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      